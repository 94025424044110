import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import FileList from "./FileList"
import ImageLoader from "./ImageLoader"
import Hotkeys from "./Hotkeys"
import classNames from "classnames"
import api from "../api"
import { initialFetch } from "../reducers/loadFiles"
import ArtistModalHandler from "./ArtistModal"

export default function FileBrowser({}) {
  const dispatch = useDispatch()
  const portraitMode = window.innerHeight > window.innerWidth
  const columns = portraitMode ? Math.floor((window.innerWidth - 36) / 144) : 3
  const [loaded, setLoaded] = useState(false)
  const bigTiles = useSelector((state) => !!state.bigTilesColumns)
  const fullscreen = useSelector((state) => state.fullscreen)

  useEffect(() => {
    api()
      .GET(`/libraries/get_libraries`)
      .then((response) => {
        dispatch({ type: "SET_STUFF", payload: { libraries: response } })
        return api().GET(`/lists/get_lists`)
      })
      .then((response) => {
        dispatch({ type: "SET_STUFF", payload: { lists: response } })
        dispatch(initialFetch()).then(() => setLoaded(true))
      })
  }, [])

  return (
    <div
      className={classNames("flex w-full h-full justify-between", {
        "flex-col space-y-4": portraitMode,
        "space-x-4": !portraitMode,
      })}
    >
      {loaded && (
        <>
          <Hotkeys />
          <ArtistModalHandler />
          {(fullscreen || !bigTiles) && <ImageLoader portraitMode={portraitMode} columns={columns} />}
          <FileList portraitMode={portraitMode} columns={columns} />
        </>
      )}
    </div>
  )
}
