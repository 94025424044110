import { useEffect, useState, useRef, useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"
import { updateMetadata, cutVideoSegment, rotateImage } from "../reducers/updateFiles"
import { FileNameTag } from "./FileTile"
import debounce from "lodash/debounce"
import { imageSrc } from "./Hotkeys"

export default function ImageLoader({ portraitMode, columns }) {
  const dispatch = useDispatch()
  const [loadedFiles, setLoadedFiles] = useState(new SpecialQueue([]))
  const preloadFile = useSelector((state) => state.preloadFile)
  const currentIndex = useSelector((state) => state.currentIndex)
  const files = useSelector((state) => state.files)
  const file = files[currentIndex]
  const currentId = file?.id
  const listId = useSelector((state) => state.listId)
  const artistName = useSelector((state) => state.artistName)
  const displayName = file && (listId && !artistName && file.file_type !== "Artist" ? file.artist_name : file.name)
  const fullscreen = useSelector((state) => state.fullscreen)
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth)
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight)
  const containerRef = useRef()
  const [cropStart, setCropStart] = useState(null)
  const [cropEnd, setCropEnd] = useState(null)
  const [style, divStyle, ratio] = getImageSizing(file, viewportWidth, viewportHeight)
  const metadata = file?.metadata && JSON.parse(file.metadata)
  let imageCropped = false
  if (metadata && (metadata.clipTop || metadata.clipRight || metadata.clipBottom || metadata.clipLeft)) {
    imageCropped = true
  }
  if (!imageCropped) {
  }

  useEffect(() => {
    const newFiles = [currentIndex, currentIndex + 1, currentIndex - 1].map((i) => files[i]).filter((file) => file)
    setLoadedFiles(loadedFiles.dup().add(newFiles))
  }, [files, currentIndex])

  useEffect(() => {
    if (preloadFile) {
      const updated = loadedFiles.dup().add([preloadFile])
      if (files[currentIndex]) updated.add([files[currentIndex]])
      setLoadedFiles(updated)
    }
  }, [preloadFile])

  useEffect(() => {
    setTimeout(() => {
      setViewportWidth(containerRef.current.offsetWidth)
      setViewportHeight(containerRef.current.offsetHeight)
    }, 100)
  }, [fullscreen])

  const showCursor = () => {
    containerRef.current.classList.remove("cursor-none")
    containerRef.current?.removeEventListener("mousemove", showCursor)
    console.log("test")
  }

  useEffect(() => {
    containerRef.current.classList.add("cursor-none")
    containerRef.current.addEventListener("mousemove", showCursor)
    return () => containerRef.current?.removeEventListener("mousemove", showCursor)
  }, [currentIndex])

  const onMouseDown = (e) => {
    if (imageCropped || !ratio) return
    e.preventDefault()
    setCropStart({ x: e.clientX, y: e.clientY })
  }

  const onMouseUp = (e) => {
    if (!cropStart || !ratio) return
    const { clientX, clientY } = e
    let left = Math.max((Math.min(clientX, cropStart.x) - divStyle.left) / ratio, 0)
    let right = Math.min((Math.max(clientX, cropStart.x) - divStyle.left) / ratio, file.width)
    let top = Math.max((Math.min(clientY, cropStart.y) - divStyle.top) / ratio, 0)
    let bottom = Math.min((Math.max(clientY, cropStart.y) - divStyle.top) / ratio, file.height)
    let width = right - left
    let height = bottom - top
    if (width > 200 && height > 200) {
      console.log([width, height])
      dispatch(
        updateMetadata(
          file,
          JSON.stringify({ ...metadata, clipTop: top, clipRight: right, clipBottom: bottom, clipLeft: left })
        )
      ).then(() => {
        setCropStart(null)
        setCropEnd(null)
      })
    } else {
      setCropStart(null)
      setCropEnd(null)
    }
  }

  const onMouseMove = (e) => {
    if (!cropStart || !ratio) return
    const { clientX, clientY } = e
    setCropEnd({ x: clientX, y: clientY })
  }

  const onWheel = (e) => {
    if (e.deltaY > 0) {
      dispatch(rotateImage(file, 45))
    } else if (e.deltaY < 0) {
      dispatch(rotateImage(file, -45))
    }
  }

  const pressRight = (e) => {
    document.dispatchEvent(new KeyboardEvent("keydown", { code: "ArrowRight" }))
  }

  const pressLeft = (e) => {
    e.preventDefault()
    document.dispatchEvent(new KeyboardEvent("keydown", { code: "ArrowLeft" }))
  }

  useEffect(() => {
    setCropStart(null)
    if (containerRef.current) {
      containerRef.current.addEventListener("mousedown", onMouseDown)
      return () => containerRef.current?.removeEventListener("mousedown", onMouseDown)
    }
  }, [file, viewportWidth, viewportHeight])

  useEffect(() => {
    if (cropStart && containerRef.current) {
      containerRef.current.addEventListener("mouseup", onMouseUp)
      containerRef.current.addEventListener("mousemove", onMouseMove)
      return () => {
        containerRef.current?.removeEventListener("mouseup", onMouseUp)
        containerRef.current?.removeEventListener("mousemove", onMouseMove)
      }
    }
  }, [cropStart])

  let cropStyle = { display: "none" }
  if (cropStart && cropEnd) {
    let left = Math.min(cropEnd.x, cropStart.x)
    let right = Math.max(cropEnd.x, cropStart.x)
    let top = Math.min(cropEnd.y, cropStart.y)
    let bottom = Math.max(cropEnd.y, cropStart.y)
    let width = right - left
    let height = bottom - top
    cropStyle = { left, top, width, height }
  }

  return (
    <div
      ref={containerRef}
      className="relative flex items-center justify-center flex-1 overflow-hidden"
      style={{ height: portraitMode ? "calc(100% - 520px)" : "100%" }}
    >
      <div style={cropStyle} className="absolute border border-gray-800 pointer-events-none z-30" />
      <div style={divStyle} onWheel={onWheel}>
        {loadedFiles.to_a().map((file) =>
          [".mp4", ".webm", ".ogg"].includes(file.file_type) ? (
            <Video key={file.id} active={file.id === currentId} file={file} style={style} />
          ) : (
            <img
              // src={`http://${window.apiRoot}/images/get_image/${file.image_id}`}
              src={imageSrc(file)}
              key={file.id + " " + file.width}
              style={file.id === currentId ? style : { display: "none" }}
            />
          )
        )}
      </div>

      {file && (
        <div
          style={{ right: 8, bottom: 8 }}
          className="absolute bottom-0 group flex flex-col items-center py-1 px-2 bg-gray-400 bg-opacity-50 rounded-lg"
          title={file.path || displayName}
        >
          <FileNameTag file={file} displayName={displayName} longName />
        </div>
      )}
    </div>
  )
}

function Video({ file, active, style }) {
  const dispatch = useDispatch()
  const ref = useRef()
  const metadata = file.metadata ? JSON.parse(file.metadata) : {}

  useEffect(() => {
    if (ref.current && metadata.start_time > 0) {
      ref.current.currentTime = metadata.start_time
      ref.current.volume = metadata.hasOwnProperty("volume") ? metadata.volume : 0.2
    }
  }, [])

  useEffect(() => {
    if (!ref.current) return
    if (active) {
      ref.current.play()
    } else {
      ref.current.pause()
    }
  }, [active])

  const startSegment = (e) => {
    if (active) {
      console.log("startSegment")
      dispatch(
        updateMetadata(file, JSON.stringify({ ...metadata, start_time: Math.max(ref.current.currentTime - 1, 0) }))
      )
    }
  }
  const endSegment = (e) => {
    if (active) {
      console.log("endSegment")
      const segmentEnd = ref.current.currentTime
      dispatch(cutVideoSegment(file, segmentEnd))
    }
  }
  const jumpForward = (e) => {
    if (active) {
      console.log("jumpForward")
      ref.current.currentTime = Math.min(ref.current.currentTime + 10, file.duration)
    }
  }
  const jumpBack = (e) => {
    if (active) {
      console.log("jumpBack")
      ref.current.currentTime = Math.max(ref.current.currentTime - 10, 0)
    }
  }
  const smallJumpForward = (e) => {
    if (active) {
      console.log("smallJumpForward")
      ref.current.currentTime = Math.min(ref.current.currentTime + 5, file.duration)
    }
  }
  const smallJumpBack = (e) => {
    if (active) {
      console.log("smallJumpBack")
      ref.current.currentTime = Math.max(ref.current.currentTime - 5, 0)
    }
  }
  const saveVolume = useCallback(
    debounce((value) => {
      console.log("saveVolume")
      dispatch(updateMetadata(file, JSON.stringify({ ...metadata, volume: value })))
    }, 300)
  )

  useEffect(() => {
    window.addEventListener("startSegment", startSegment)
    window.addEventListener("endSegment", endSegment)
    window.addEventListener("jumpForward", jumpForward)
    window.addEventListener("jumpBack", jumpBack)
    window.addEventListener("smallJumpForward", smallJumpForward)
    window.addEventListener("smallJumpBack", smallJumpBack)
    return () => {
      window.removeEventListener("startSegment", startSegment)
      window.removeEventListener("endSegment", endSegment)
      window.removeEventListener("jumpForward", jumpForward)
      window.removeEventListener("jumpBack", jumpBack)
      window.removeEventListener("smallJumpForward", smallJumpForward)
      window.removeEventListener("smallJumpBack", smallJumpBack)
    }
  })
  return (
    <video
      ref={ref}
      key={file.id}
      style={active ? style : { display: "none" }}
      controls
      loop={file.duration < 7}
      preload="auto"
      onVolumeChange={(e) => saveVolume(e.target.volume)}
    >
      <source
        // src={`http://${window.apiRoot}/images/get_image/${file.image_id}`}
        src={imageSrc(file)}
        type={`video/${file.file_type.slice(1, file.file_type.length)}`}
      />
    </video>
  )
}

class SpecialQueue {
  constructor(list, maxSize = 20) {
    this.set = new Set(list.map((x) => x.id))
    this.array = [...list]
    this.maxSize = maxSize
  }

  add(list) {
    let shiftCount = this.array.length + list.length - this.maxSize
    list.forEach((x) => {
      if (this.set.has(x.id)) {
        shiftCount--
        const index = this.array.findIndex((y) => y.id === x.id)
        if (index >= 0) this.array.splice(index, 1)
      }
    })
    for (; shiftCount > 0; shiftCount--) {
      this.array.shift()
    }
    list.forEach((x) => this.array.push(x))
    return this
  }

  dup() {
    return new SpecialQueue(this.array, this.maxSize)
  }

  to_a() {
    return this.array
  }
}

function getImageSizing(file, viewportWidth, viewportHeight) {
  if (!file || !file.width || !file.height) {
    return [
      { maxWidth: "100%", maxHeight: "100%" },
      { display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" },
    ]
  }
  const rotation = file.rotation
  const rotated = rotation % 180 !== 0 && (rotation % 90 === 0 || file.width > file.height)
  let fileHeight = rotated ? file.width : file.height
  let fileWidth = rotated ? file.height : file.width

  let { clipTop, clipRight, clipBottom, clipLeft } = file.metadata ? JSON.parse(file.metadata) : {}
  clipTop = clipTop || 0
  clipRight = clipRight || fileWidth
  clipBottom = clipBottom || fileHeight
  clipLeft = clipLeft || 0
  let clippedWidth = clipRight - clipLeft
  let clippedHeight = clipBottom - clipTop
  let ratio = 1
  if (clippedHeight > viewportHeight && clippedWidth <= viewportWidth) {
    ratio = viewportHeight / clippedHeight
  } else if (clippedWidth > viewportWidth && clippedHeight <= viewportHeight) {
    ratio = viewportWidth / clippedWidth
  } else if (clippedHeight > viewportHeight && clippedWidth > viewportWidth) {
    ratio = Math.min(viewportHeight / clippedHeight, viewportWidth / clippedWidth)
  }
  if (rotated) {
    ;[clipTop, clipRight, clipBottom, clipLeft, clippedWidth, clippedHeight, fileWidth, fileHeight] = [
      clipLeft,
      clipBottom,
      clipRight,
      clipTop,
      clippedHeight,
      clippedWidth,
      fileHeight,
      fileWidth,
    ]
  }
  clipTop = ratio * clipTop
  clipRight = ratio * clipRight
  clipBottom = ratio * clipBottom
  clipLeft = ratio * clipLeft
  clippedWidth = ratio * clippedWidth
  clippedHeight = ratio * clippedHeight
  const divHeight = fileHeight * ratio
  const divWidth = fileWidth * ratio

  const style = {
    clip: `rect(${clipTop}px,${clipRight}px,${clipBottom}px,${clipLeft}px)`,
    position: "absolute",
    width: divWidth,
    height: divHeight,
    top: (divHeight - clippedHeight) / 2 - clipTop,
    left: (divWidth - clippedWidth) / 2 - clipLeft,
  }

  const divTop = (viewportHeight - divHeight) / 2
  const divLeft = (viewportWidth - divWidth) / 2
  const divStyle = {
    position: "absolute",
    height: divHeight,
    width: divWidth,
    top: divTop,
    left: divLeft,
    transform: `rotate(${rotation}deg)`,
  }
  return [style, divStyle, ratio]
}
