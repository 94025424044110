const globalInitialState = {
  libraryId: null,
  listId: null,
  artistName: null,
  albumName: null,
  folderId: null,
  files: [],
  lists: [],
  libraries: [],
  totalFiles: 0,
  path: [],
  history: [],
  historyIndex: 0,
  currentIndex: 0,
  selectionStart: 0,
  fullscreen: false,
  preloadFile: null,
  showDeleted: false,
  typing: false,
  filterText: "",
  filterFunction: null,
  unfilteredFiles: null,
  editingArtist: null,
  segmentStart: null,
  bigTilesColumns: null,
  bigTilesRows: null,
  bigTilesMin: 0,
  bigTilesMax: 0,
}

export default function rootReducer(state = globalInitialState, action) {
  console.log(action.type)
  return {
    ...globalReducer(state, action),
    locationPersist: locationReducer(state.locationPersist, action),
  }
}

const locationInitialState = {
  libraryId: null,
  listId: null,
  artistName: null,
  albumName: null,
  folderId: null,
}

function locationReducer(state = locationInitialState, action) {
  if (action.type === "SET_STUFF") {
    state = { ...locationInitialState, ...state }
    for (const key in action.payload) {
      if (state.hasOwnProperty(key)) {
        state[key] = action.payload[key]
      }
    }
  }
  return state
}

function globalReducer(state = globalInitialState, action) {
  if (action.type === "SET_STUFF") {
    const prevFile = state.files[state.currentIndex]
    state = { ...state }
    const file = state.files[state.currentIndex]
    const fileId = file?.id
    for (const key in action.payload) {
      if (state.hasOwnProperty(key)) {
        state[key] = action.payload[key]
      }
    }
    if (action.payload.hasOwnProperty("filterFunction") && !action.payload.filterFunction && state.unfilteredFiles) {
      state.files = state.unfilteredFiles
      state.unfilteredFiles = null
      state.currentIndex = Math.max(
        state.files.findIndex((f) => f.id === fileId),
        0
      )
      state.selectionStart = state.currentIndex
    } else if (action.payload.filterFunction && state.filterFunction) {
      if (!state.unfilteredFiles) {
        state.unfilteredFiles = state.files
      }
      state.files = state.unfilteredFiles.filter(state.filterFunction)
      state.currentIndex = Math.max(
        state.files.findIndex((f) => f.id === fileId),
        0
      )
      state.selectionStart = state.currentIndex
    } else if (action.payload.files && state.filterFunction) {
      state.unfilteredFiles = state.files
      state.files = state.unfilteredFiles.filter(state.filterFunction)
      state.currentIndex = Math.max(
        state.files.findIndex((f) => f.id === fileId),
        0
      )
      state.selectionStart = state.currentIndex
    }
    if (!file || file !== prevFile) {
      state.segmentStart = null
    }
    if (state.bigTilesColumns && state.bigTilesRows) {
      const size = state.bigTilesColumns * state.bigTilesRows
      const fileCount = state.files.length
      let max = state.bigTilesMax
      let min = state.bigTilesMin
      if (state.currentIndex > max) {
        max = roundUp(state.currentIndex, state.bigTilesColumns)
        min = Math.max(0, max - size + 1)
        max = Math.min(fileCount - 1, min + size - 1)
      } else if (state.currentIndex < min || max - min + 1 !== size) {
        min = roundDown(state.currentIndex, state.bigTilesColumns)
        max = Math.min(fileCount - 1, min + size - 1)
        min = Math.max(0, max - size + 1)
      }
      state.bigTilesMax = max
      state.bigTilesMin = min
    }
  }
  return state
}

function roundUp(value, divisor) {
  return Math.ceil((value + 1) / divisor) * divisor - 1
}

function roundDown(value, divisor) {
  return Math.floor(value / divisor) * divisor
}
