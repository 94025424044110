import React, { useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import classNames from "classnames"
import { navigateTo } from "../reducers/loadFiles"
import { selectFileWithoutIndex, setRatings, toggleReviewed, restoreFiles } from "../reducers/updateFiles"
import { ReactComponent as StarIcon } from "../images/star.svg"
import { ReactComponent as ClearIcon } from "../images/no.svg"
import { ReactComponent as CheckIcon } from "../images/check.svg"
import { ReactComponent as DeleteIcon } from "../images/delete.svg"
import { imageSrc, thumbnailSrc } from "./Hotkeys"

function FileTile({ file, selected, displayName }) {
  const dispatch = useDispatch()

  const ref = useRef()
  useEffect(() => {
    if (selected) {
      ref.current?.scrollIntoView({ behavior: "auto", block: "nearest" })
    }
  }, [selected])
  const isFolder = ["Folder", "Artist", "Album"].includes(file.file_type)

  return (
    <div
      ref={ref}
      className={classNames("flex flex-col items-center rounded-lg group", {
        "p-2": !selected,
        "p-6px border-2 border-gray-400": selected,
      })}
      onClick={(e) => dispatch(selectFileWithoutIndex(file, e))}
      onDoubleClick={() => dispatch(navigateTo(file))}
      /* onMouseEnter={() => dispatch({ type: "SET_STUFF", payload: { preloadFile: file } })} */
      title={displayName}
    >
      <div
        className={classNames("flex items-center justify-center overflow-hidden w-32 h-32 rounded-lg", {
          "bg-gray-600 bg-opacity-50": true,
          "-m-2px border-2 border-gray-400 box-content": isFolder && !selected,
        })}
      >
        {/* <img src={`http://${window.apiRoot}/images/get_thumbnail/${file.image_id}.png`} /> */}
        {/* <img
          src={`https://good-file-manager-storage.s3.us-west-2.amazonaws.com/gfm_thumbnails/${file.file_hash}.png`}
        /> */}
        <img className="mb-2" src={thumbnailSrc(file)} />
      </div>
      <FileNameTag file={file} displayName={displayName} />
    </div>
  )
}

export default React.memo(FileTile)

export function BigTile({ file, selected, displayName, hidden }) {
  const dispatch = useDispatch()
  const windowWidth = window.innerWidth - 72
  const windowHeight = window.innerHeight - 160 + 8
  const columns = useSelector((state) => state.bigTilesColumns)
  const rows = useSelector((state) => state.bigTilesRows)
  const width = Math.floor(windowWidth / columns) - 12
  const height = Math.floor(windowHeight / rows) - 60

  return (
    <div
      className={classNames("flex flex-col items-center group p-2 border-2 border-transparent rounded-lg", {
        "border-gray-400": selected,
        hidden: hidden,
      })}
      onClick={(e) => dispatch(selectFileWithoutIndex(file, e))}
      onDoubleClick={() => dispatch(navigateTo(file))}
      title={displayName}
    >
      <div
        className={classNames("flex items-center justify-center overflow-hidden", {
          "bg-gray-600 bg-opacity-50": true,
        })}
        style={{ width, height }}
      >
        {/* <img className="max-w-full max-h-full" src={`http://${window.apiRoot}/images/get_image/${file.image_id}`} /> */}
        <img className="mb-2" src={imageSrc(file)} />
      </div>
      <FileNameTag file={file} displayName={displayName} />
    </div>
  )
}

export function FileNameTag({ file, displayName, longName }) {
  const dispatch = useDispatch()
  return (
    <>
      <div
        className={classNames(
          "relative flex items-center w-17 h-4 px-1 rounded mt-1 mx-4 group-hover:bg-gray-600 group-hover:bg-opacity-50"
        )}
      >
        <button
          style={{ left: -16 }}
          className="absolute w-4 h-4 rounded-full focus:outline-none invisible group-hover:visible text-gray-300 hover:text-gray-900"
          onClick={() => dispatch(setRatings([file], 0))}
        >
          <ClearIcon className="w-3 h-3 fill-current flex-shrink-0" />
        </button>
        {[1, 2, 3, 4, 5].map((i) => (
          <button
            key={i}
            className={classNames("focus:outline-none", {
              "text-gray-200": file.rating >= i,
              "invisible group-hover:visible text-gray-400 hover:text-black": file.rating < i,
            })}
            onClick={() => dispatch(setRatings([file], i))}
          >
            <StarIcon className="w-3 h-3 fill-current flex-shrink-0" />
          </button>
        ))}
        {file.delete_status > 0 ? (
          <button
            style={{ right: -20 }}
            className={classNames("absolute w-4 h-4 rounded-full focus:outline-none", {
              "text-red-700 hover:text-gray-300": file.delete_status > 0,
            })}
            onClick={() => dispatch(restoreFiles([file]))}
          >
            <DeleteIcon className="w-3 h-3 fill-current flex-shrink-0" />
          </button>
        ) : (
          <button
            style={{ right: -20 }}
            className={classNames("absolute w-4 h-4 rounded-full focus:outline-none", {
              "invisible group-hover:visible text-gray-300 hover:text-green-500": file.status === 0,
              "text-green-500": file.status > 0,
              "invisible cursor-default": file.status == null,
            })}
            onClick={() => dispatch(toggleReviewed([file]))}
          >
            <CheckIcon className="w-3 h-3 fill-current flex-shrink-0" />
          </button>
        )}
      </div>
      <div className={classNames("text-14 truncate text-gray-200", { "w-32": !longName, "max-w-48": longName })}>
        {displayName}
      </div>
    </>
  )
}
