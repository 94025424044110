import api from "../api"
import { useSelector } from "react-redux"

export const useSelect = (field) => useSelector((state) => state[field])

export const fetchFiles = ({ folderId, libraryId, listId, artistName, albumName, updateHistory = true }) => {
  return (dispatch, getStore) => {
    const selectedId = getStore().files[getStore().currentIndex]?.id
    const { showDeleted, filterText } = getStore()

    const processResponse = (response) => {
      let newIndex = response.files.findIndex((file) => file.id == selectedId)
      const { folderId, albumName, artistName, listId } = getStore()
      if (newIndex < 0) {
        if (folderId && !response.list_id) {
          newIndex = response.files.findIndex((file) => file.id == folderId)
        } else if (artistName && !albumName && response.list_id === listId && !response.artist_name) {
          newIndex = response.files.findIndex((file) => file.name == artistName)
        } else if (albumName && response.list_id === listId && response.artist_name && !response.album_name) {
          newIndex = response.files.findIndex((file) => file.name == albumName)
        }
      }
      const payload = {
        libraryId: response.library_id,
        listId: response.list_id,
        artistName: response.artist_name,
        albumName: response.album_name,
        folderId: response.folder_id,
        currentIndex: newIndex >= 0 ? newIndex : 0,
        selectionStart: newIndex >= 0 ? newIndex : 0,
        path: response.path,
        files: response.files,
        // totalFiles: response.total_files,
      }
      if (updateHistory) {
        payload.history = [
          ...getStore().history.slice(0, getStore().historyIndex + 1),
          {
            folderId: response.folder_id,
            libraryId: response.library_id,
            listId: response.list_id,
            artistName: response.artist_name,
            albumName: response.album_name,
          },
        ]
        payload.historyIndex = getStore().historyIndex + 1
      }
      return dispatch({ type: "SET_STUFF", payload })
    }

    if (folderId) {
      return api()
        .GET(`/files/list_files`, {
          library_id: libraryId,
          folder_id: folderId,
          show_deleted: showDeleted,
          filter_text: filterText || null,
        })
        .then(processResponse)
    } else {
      return api()
        .GET(`/files/list_files`, {
          library_id: libraryId,
          list_id: listId,
          artist_name: artistName,
          album_name: albumName,
          show_deleted: showDeleted,
          filter_text: filterText || null,
        })
        .then(processResponse)
    }
  }
}

export const openFolder = (id) => {
  return (dispatch, getStore) => {
    dispatch(
      fetchFiles({
        folderId: id,
        libraryId: getStore().libraryId,
        listId: getStore().listId,
        artistName: getStore().artistName,
        albumName: getStore().albumName,
      })
    )
  }
}

export const openLibrary = (id) => {
  return (dispatch, getStore) => {
    dispatch(
      fetchFiles({
        folderId: null,
        libraryId: id,
        listId: null,
        artistName: null,
        albumName: null,
      })
    )
  }
}

export const openList = (id) => {
  return (dispatch, getStore) => {
    const list = getStore().lists.find((l) => l.id == id)
    dispatch(
      fetchFiles({
        folderId: null,
        libraryId: list?.library_id || getStore().libraryId,
        listId: id,
        artistName: null,
        albumName: null,
      })
    )
  }
}

export const openArtist = (name) => {
  return (dispatch, getStore) => {
    dispatch(
      fetchFiles({
        folderId: null,
        libraryId: getStore().libraryId,
        listId: getStore().listId,
        artistName: name,
        albumName: null,
      })
    )
  }
}

export const openAlbum = (artistName, albumName) => {
  return (dispatch, getStore) => {
    dispatch(
      fetchFiles({
        folderId: null,
        libraryId: getStore().libraryId,
        listId: getStore().listId,
        artistName,
        albumName,
      })
    )
  }
}

export const navigateTo = (file) => {
  return (dispatch) => {
    if (!file) {
      return
    } else if (file.file_type === "Folder") {
      dispatch(openFolder(file.id))
    } else if (file.file_type === "Artist") {
      dispatch(openArtist(file.name))
    } else if (file.file_type === "Album") {
      dispatch(openAlbum(file.artist_name, file.name))
    } else if (file.file_type === "List") {
      dispatch(openList(file.id))
    }
  }
}

export const updateFilters = (options) => {
  return (dispatch, getStore) => {
    const { showDeleted, filterText } = options
    const prevFilterText = getStore().filterText
    dispatch({ type: "SET_STUFF", payload: options })
    if (showDeleted !== undefined || filterText !== prevFilterText) {
      return dispatch(fetchFiles(getStore()))
    }
  }
}

export const initialFetch = () => {
  return (dispatch, getStore) => {
    return dispatch(fetchFiles(getStore().locationPersist))
  }
}

export const refreshList = (listId) => {
  return (dispatch, getStore) => {
    api()
      .POST(`/lists/refresh_list`, { id: listId })
      .then(() => {
        return dispatch(fetchFiles(getStore()))
      })
  }
}
