import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import api from "../api"
import {
  setRatings,
  markViewed,
  selectFile,
  toggleReviewed,
  deleteFiles,
  showInFolder,
  restoreFiles,
  editArtist,
  uncropImage,
  fixRotation,
  rotateImage,
  upscaleImage,
  filterArtist,
} from "../reducers/updateFiles"
import { navigateTo } from "../reducers/loadFiles"

export default function Hotkeys() {
  const dispatch = useDispatch()
  const handler = (e) => {
    console.log(e)
    const code = `${e.ctrlKey ? "Control+" : ""}${e.shiftKey ? "Shift+" : ""}${e.altKey ? "Alt+" : ""}${e.code}`
    for (const action in keybinds) {
      if (keybinds[action].includes(code)) {
        dispatch(handleKeypress(action, e))
        break
      }
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handler, false)
    return () => document.removeEventListener("keydown", handler, false)
  }, [])

  return null
}

const keybinds = {
  navigateRight: ["ArrowRight", "Shift+ArrowRight"],
  navigateLeft: ["ArrowLeft", "Shift+ArrowLeft"],
  navigateUp: ["ArrowUp", "Shift+ArrowUp"],
  navigateDown: ["ArrowDown", "Shift+ArrowDown"],
  navigateParent: ["Control+ArrowUp", "Backspace"],
  confirm: ["Enter"],
  cancel: ["Escape"],
  setHighlightImage: ["NumpadAdd"],
  toggleFullscreen: ["Space"],
  rate0: ["Digit0", "Numpad0"],
  rate1: ["Digit1", "Numpad1"],
  rate2: ["Digit2", "Numpad2"],
  rate3: ["Digit3", "Numpad3"],
  rate4: ["Digit4", "Numpad4"],
  rate5: ["Digit5", "Numpad5"],
  toggleReviewed: ["Backslash"],
  markViewed: ["NumpadEnter"],
  deleteSelected: ["Delete", "NumpadDecimal"],
  restoreSelected: ["Control+Delete"],
  showInFolder: ["NumpadSubtract"],
  selectAll: ["Control+KeyA"],
  editArtist: ["NumpadMultiply"],
  uncropImage: ["Slash"],
  startSegment: ["KeyZ"],
  // endSegment: ["KeyX"],
  // cancelSegment: ["KeyC"],
  jumpForward: ["KeyD"],
  jumpBack: ["KeyA"],
  smallJumpForward: ["KeyE"],
  smallJumpBack: ["KeyQ"],
  fixRotation: ["KeyF"],
  rotateImage: ["KeyT"],
  rotateImageReverse: ["KeyR"],
  halfRotateImage: ["Shift+KeyT"],
  halfRotateImageReverse: ["Shift+KeyR"],
  upscaleImage: ["KeyU", "Numpad6"],
  filterArtist: ["Numpad9"],
}

export const handleKeypress = (functionName, e) => {
  return (dispatch, getStore) => {
    const {
      currentIndex,
      selectionStart,
      files,
      path,
      albumName,
      artistName,
      libraryId,
      folderId,
      fullscreen,
      typing,
      editingArtist,
      bigTilesColumns,
    } = getStore()
    if (typing || editingArtist) return
    const currentFile = files[currentIndex]
    const portraitMode = window.innerHeight > window.innerWidth
    const columns = bigTilesColumns || (portraitMode ? Math.floor((window.innerWidth - 36) / 144) : 3)

    const selectedFiles = () => {
      return files.slice(Math.min(selectionStart, currentIndex), Math.max(selectionStart, currentIndex) + 1)
    }
    const applyRating = (rating) => {
      return dispatch(setRatings(selectedFiles(), rating)).then(() =>
        dispatch(selectFile(Math.max(currentIndex, selectionStart) + 1))
      )
    }

    const hotkeyCallbacks = {
      navigateRight: (e) => dispatch(selectFile((currentIndex + 1) % files.length, e)),
      navigateLeft: (e) => dispatch(selectFile((currentIndex + files.length - 1) % files.length, e)),
      navigateDown: (e) => dispatch(selectFile((currentIndex + columns) % files.length, e)),
      navigateUp: (e) => dispatch(selectFile((currentIndex + files.length - columns) % files.length, e)),
      navigateParent: () => {
        if (path.length <= 1) return
        dispatch(navigateTo(path[1]))
      },
      setHighlightImage: () => {
        if (!currentFile) return
        if (albumName && artistName) {
          api().POST(`/albums/set_image_hash`, {
            library_id: libraryId,
            artist_name: artistName,
            album_name: albumName,
            image_hash: currentFile.image_hash,
          })
        } else if (artistName) {
          api().POST(`/artists/set_image_hash`, {
            library_id: libraryId,
            artist_name: artistName,
            image_hash: currentFile.image_hash,
          })
        } else if (folderId) {
          api().POST(`/files/set_image_hash/${folderId}/${currentFile.image_hash}`)
        }
      },
      toggleFullscreen: () => dispatch({ type: "SET_STUFF", payload: { fullscreen: !fullscreen } }),
      cancel: () => {
        if (fullscreen) {
          dispatch({ type: "SET_STUFF", payload: { fullscreen: false } })
        }
      },
      confirm: () => {
        dispatch(navigateTo(currentFile))
      },
      rate0: () => applyRating(0),
      rate1: () => applyRating(1),
      rate2: () => applyRating(2),
      rate3: () => applyRating(3),
      rate4: () => applyRating(4),
      rate5: () => applyRating(5),
      markViewed: () => {
        return dispatch(markViewed(selectedFiles())).then(() =>
          dispatch(selectFile(Math.max(currentIndex, selectionStart) + 1))
        )
      },
      toggleReviewed: () => dispatch(toggleReviewed(selectedFiles())),
      deleteSelected: () => dispatch(deleteFiles(selectedFiles())),
      restoreSelected: () => dispatch(restoreFiles(selectedFiles())),
      showInFolder: () => dispatch(showInFolder(files[currentIndex])),
      selectAll: () => dispatch({ type: "SET_STUFF", payload: { currentIndex: 0, selectionStart: files.length - 1 } }),
      editArtist: () => dispatch(editArtist(currentFile)),
      uncropImage: () => dispatch(uncropImage(currentFile)),
      startSegment: () => {
        window.dispatchEvent(new Event("startSegment"))
      },
      endSegment: () => {
        window.dispatchEvent(new Event("endSegment"))
      },
      cancelSegment: () => {
        dispatch({ type: "SET_STUFF", payload: { segmentStart: null } })
      },
      jumpForward: () => {
        window.dispatchEvent(new Event("jumpForward"))
      },
      jumpBack: () => {
        window.dispatchEvent(new Event("jumpBack"))
      },
      smallJumpForward: () => {
        window.dispatchEvent(new Event("smallJumpForward"))
      },
      smallJumpBack: () => {
        window.dispatchEvent(new Event("smallJumpBack"))
      },
      fixRotation: () => dispatch(fixRotation(currentFile)),
      rotateImage: () => dispatch(rotateImage(currentFile, 90)),
      rotateImageReverse: () => dispatch(rotateImage(currentFile, 270)),
      halfRotateImage: () => dispatch(rotateImage(currentFile, 45)),
      halfRotateImageReverse: () => dispatch(rotateImage(currentFile, 315)),
      upscaleImage: () => dispatch(upscaleImage(currentFile)),
      filterArtist: () => dispatch(filterArtist(currentFile)),
    }

    const callback = hotkeyCallbacks[functionName]
    if (callback) {
      e.preventDefault()
      e.stopPropagation()
      callback(e)
    }
  }
}

export function imageSrc(file) {
  return `https://127.0.0.1:3001/files/${file.image_hash?.slice(0, 2)}/${file.image_hash}`
}

export function thumbnailSrc(file) {
  return `https://127.0.0.1:3001/thumbnails/${file.image_hash?.slice(0, 2)}/${file.image_hash}.png`
}
