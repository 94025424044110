import api from "../api"
import { setFilterText } from "../components/FileList"
import { fetchFiles, navigateTo } from "./loadFiles"

const isRealFile = (item) => item.file_type !== "Artist" && item.file_type !== "Album"

export const selectFile = (index, e) => {
  return (dispatch) => {
    const payload = { currentIndex: index }
    if (!e || !e.shiftKey) payload.selectionStart = index
    dispatch({ type: "SET_STUFF", payload })
  }
}

export const selectFileWithoutIndex = (file, e) => {
  return (dispatch, getStore) => {
    const index = getStore().files.findIndex((f) => f === file)
    dispatch(selectFile(index, e))
  }
}

export const applyUpdateToItems = (items, changes) => {
  return (dispatch, getStore) => {
    const byId = {}
    items.forEach((item) => (byId[item.id] = item))
    const files = [...getStore().files]
    files.forEach((file, i) => {
      if (byId[file.id]) files[i] = { ...file, ...changes }
    })
    dispatch({ type: "SET_STUFF", payload: { files } })
  }
}

export const setRatings = (items, rating) => {
  return (dispatch, getStore) => {
    return api()
      .POST(`/files/set_ratings`, { library_id: getStore().libraryId, rating, items })
      .then(() => {
        const updates = { rating }
        if (rating > 0) updates.status = 1
        if (rating === 0) updates.status = 0
        dispatch(applyUpdateToItems(items, updates))
      })
  }
}

export const markViewed = (items) => {
  return (dispatch, getStore) => {
    return api().POST(`/files/mark_viewed`, { library_id: getStore().libraryId, items })
  }
}

export const toggleReviewed = (items) => {
  return (dispatch) => {
    items = items.filter(isRealFile)
    const status = items.some((i) => i.status === 0) ? 1 : 0
    const ids = items.map((i) => i.id)
    return api()
      .POST(`/files/set_status`, { status, ids })
      .then(() => {
        dispatch(applyUpdateToItems(items, { status }))
      })
  }
}

export const restoreFiles = (files) => {
  return (dispatch) => {
    return api()
      .POST(`/files/restore_files`, { ids: files.map((f) => f.id) })
      .then(() => {
        dispatch(applyUpdateToItems(files, { delete_status: 0 }))
      })
  }
}

export const deleteFiles = (items) => {
  return (dispatch, getStore) => {
    let folderCount = 0
    items = items.filter((item) => {
      if (item.file_type === "Folder") folderCount++
      return isRealFile(item)
    })
    if (folderCount > 0 && !window.confirm(`${folderCount} folders will be deleted. Continue?`)) return
    console.log(items)
    return api()
      .DELETE(`/files/delete_files`, { ids: items.map((i) => i.id) })
      .then(() => {
        if (getStore().showDeleted || items.length <= 1) {
          dispatch(applyUpdateToItems(items, { delete_status: 1 }))
          dispatch(selectFile(Math.max(getStore().currentIndex, getStore().selectionStart) + 1))
        } else {
          const byId = {}
          items.forEach((item) => (byId[item.id] = item))
          const files = getStore().files.filter((file) => !byId[file.id])
          const index = Math.max(getStore().currentIndex, getStore().selectionStart) + 1 - (items.length % files.length)
          dispatch({ type: "SET_STUFF", payload: { files, currentIndex: index, selectionStart: index } })
        }
      })
  }
}

export const showInFolder = (file) => {
  return (dispatch, getStore) => {
    if (!file.parent_id || getStore().folderId) return
    dispatch(
      fetchFiles({
        folderId: file.parent_id,
        libraryId: file.library_id,
        listId: getStore().libraryId,
        artistName: null,
        albumName: null,
      })
    )
  }
}

export const editArtist = (file) => {
  return (dispatch, getStore) => {
    if (!file) return
    const name = file.file_type === "Artist" ? file.name : file.artist_name
    if (!name) return
    api()
      .GET(`/artists/get_artist`, { library_id: getStore().libraryId, artist_name: name })
      .then((response) => dispatch({ type: "SET_STUFF", payload: { editingArtist: response } }))
  }
}

export const filterArtist = (file) => {
  return (dispatch, getStore) => {
    if (!file) return
    const name = file.file_type === "Artist" ? file.name : file.artist_name
    if (!name) return
    let filterText = getStore().filterText || ""
    if (filterText.startsWith("f.")) return
    const regex = new RegExp(`(where | and )artist_name = "${name}"`, "i")
    // if (filterText.match(regex)) {
    // filterText = filterText.replace(regex, "")
    if (filterText) {
      filterText += `and artist_name = "${name}"`
    } else {
      filterText = `where artist_name = "${name}"`
    }
    dispatch(setFilterText(filterText))
  }
}

export const updateMetadata = (file, metadata) => {
  return (dispatch) => {
    return api()
      .POST(`/files/update_metadata`, { id: file.id, metadata: metadata })
      .then(() => {
        dispatch(applyUpdateToItems([file], { metadata }))
      })
  }
}

export const uncropImage = (file) => {
  return (dispatch) => {
    if (!file.metadata) return
    let metadata = JSON.parse(file.metadata)
    delete metadata.clipTop
    delete metadata.clipRight
    delete metadata.clipBottom
    delete metadata.clipLeft
    metadata = JSON.stringify(metadata)
    return dispatch(updateMetadata(file, metadata))
  }
}

export const cutVideoSegment = (file, segmentEnd) => {
  return (dispatch, getStore) => {
    const { segmentStart } = getStore()
    if (file && segmentEnd > segmentStart) {
      console.log(`cutVideoSegment ${segmentStart}s to ${segmentEnd}s`)
      const duration = segmentEnd - segmentStart
      api()
        .POST(`/files/cut_video_segment`, { id: file.id, segment_start: segmentStart, duration })
        .then((response) => {
          if (!response.id) return
          const index = getStore().currentIndex
          const files = getStore().files
          dispatch({
            type: "SET_STUFF",
            payload: {
              files: [...files.slice(0, index), response, ...files.slice(index, files.length)],
            },
          })
        })
    }
  }
}

export const fixRotation = (file) => {
  return (dispatch, getStore) => {
    api()
      .POST(`/files/fix_rotation`, { id: file.id })
      .then((response) => {
        dispatch(applyUpdateToItems([file], response))
      })
  }
}

export const rotateImage = (file, degrees = 90) => {
  return (dispatch, getStore) => {
    api()
      .POST(`/files/rotate_image`, { id: file.id, degrees })
      .then((response) => {
        dispatch(applyUpdateToItems([file], response))
      })
  }
}

export const upscaleImage = (file) => {
  return (dispatch, getStore) => {
    api()
      .POST(`/files/upscale_image`, { id: file.id })
      .then((response) => {
        dispatch(applyUpdateToItems([file], response))
      })
  }
}
