import { useSelector, useDispatch } from "react-redux"
import {
  useSelect,
  openLibrary,
  openList,
  fetchFiles,
  navigateTo,
  updateFilters,
  refreshList,
} from "../reducers/loadFiles"
import classNames from "classnames"
import FileTile, { BigTile } from "./FileTile"
import { ReactComponent as ArrowRight } from "../images/arrow-right.svg"
import { ReactComponent as ArrowLeft } from "../images/arrow-left.svg"
import { ReactComponent as RefreshIcon } from "../images/refresh.svg"

export default function FileList({ portraitMode, columns }) {
  const dispatch = useDispatch()
  const fullscreen = useSelect("fullscreen")
  const files = useSelect("files")
  const currentIndex = useSelect("currentIndex")
  const selectionStart = useSelect("selectionStart")
  const listId = useSelect("listId")
  const artistName = useSelect("artistName")
  const bigTilesColumns = useSelector((state) => state.bigTilesColumns)
  const bigTilesRows = useSelector((state) => state.bigTilesRows)
  const bigTilesMin = useSelector((state) => state.bigTilesMin)
  const bigTilesMax = useSelector((state) => state.bigTilesMax)
  const min = Math.min(currentIndex, selectionStart)
  const max = Math.max(currentIndex, selectionStart)

  const onWheel = (e) => {
    if (!bigTilesColumns) return
    if (e.deltaY > 0) {
      document.dispatchEvent(new KeyboardEvent("keydown", { code: "ArrowDown" }))
      // dispatch(selectFile((currentIndex + columns) % files.length, e))
    } else if (e.deltaY < 0) {
      document.dispatchEvent(new KeyboardEvent("keydown", { code: "ArrowUp" }))
      // dispatch(selectFile((currentIndex + files.length - columns) % files.length, e))
    }
  }

  return (
    <div
      onWheel={onWheel}
      style={{
        width: portraitMode || bigTilesColumns ? "100%" : 144 * columns + 36,
        height: portraitMode && !bigTilesRows ? 500 : "100%",
      }}
      className={classNames("overflow-y-scroll flex-shrink-0 border-gray-400", {
        "border-l": !portraitMode && !bigTilesColumns,
        "border-t-2": portraitMode && !bigTilesColumns,
        hidden: fullscreen,
      })}
    >
      <div className="sticky top-0 w-full bg-gray-500 border-b border-gray-400 pt-1 z-10">
        <div className="flex items-center w-full">
          <LibrarySelector />
          <ListSelector />
          {listId && (
            <button
              className="ml-2 focus:outline-none text-gray-700 hover:text-gray-900"
              onClick={() => dispatch(refreshList(listId))}
            >
              <RefreshIcon className="w-4 h-4 fill-current flex-shrink-0" />
            </button>
          )}
          <BigTilesSize />
        </div>
        <HistoryBar />
        <SearchBar />
      </div>
      <div className="flex flex-wrap p-2">
        {bigTilesColumns
          ? files.map(
              (file, index) =>
                index >= bigTilesMin - bigTilesColumns &&
                index <= bigTilesMax + bigTilesColumns && (
                  <BigTile
                    key={file.id}
                    file={file}
                    selected={index >= min && index <= max}
                    displayName={listId && !artistName && file.file_type !== "Artist" ? file.artist_name : file.name}
                    hidden={index > bigTilesMax || index < bigTilesMin}
                  />
                )
            )
          : files.map((file, index) => (
              <FileTile
                key={file.id}
                file={file}
                selected={index >= min && index <= max}
                displayName={listId && !artistName && file.file_type !== "Artist" ? file.artist_name : file.name}
              />
            ))}
      </div>
    </div>
  )
}

function LibrarySelector() {
  const dispatch = useDispatch()
  const libraryId = useSelect("libraryId")
  const libraries = useSelect("libraries")
  return (
    <select onChange={(e) => dispatch(openLibrary(e.target.value))} value={libraryId}>
      {libraries.map((library) => (
        <option value={library.id} key={library.id}>
          {library.name}
        </option>
      ))}
    </select>
  )
}

function ListSelector() {
  const dispatch = useDispatch()
  const listId = useSelect("listId")
  const lists = useSelect("lists")
  return (
    <select onChange={(e) => dispatch(openList(e.target.value || null))} value={listId || ""}>
      <option value="">Folder View</option>
      {lists.map((list) => (
        <option value={list.id} key={list.id}>
          {list.name}
        </option>
      ))}
    </select>
  )
}

function BigTilesSize() {
  const dispatch = useDispatch()
  return (
    <input
      className="ml-2 focus:outline-none px-1"
      onFocus={(e) => {
        e.target.select()
        dispatch(setTyping(true))
      }}
      onBlur={() => dispatch(setTyping(false))}
      onKeyDown={(e) => {
        if (e.code === "Enter") {
          const [width, height] = e.target.value.split("x")
          console.log([width, height])
          if (width && height) {
            dispatch({
              type: "SET_STUFF",
              payload: { bigTilesColumns: parseInt(width), bigTilesRows: parseInt(height) },
            })
          } else {
            dispatch({
              type: "SET_STUFF",
              payload: { bigTilesColumns: null, bigTilesRows: null },
            })
          }
          e.target.blur()
        }
      }}
    />
  )
}

function HistoryBar() {
  const dispatch = useDispatch()
  const historyIndex = useSelect("historyIndex")
  const history = useSelect("history")
  return (
    <div className="flex items-center w-full p-1">
      <button
        disabled={historyIndex <= 0}
        className="rounded focus:outline-none hover:text-gray-900 text-gray-700 disabled:text-gray-400"
        onClick={() => {
          if (historyIndex > 0) {
            dispatch({ type: "SET_STUFF", payload: { historyIndex: historyIndex - 1 } })
            dispatch(fetchFiles({ ...history[historyIndex - 1], updateHistory: false }))
          }
        }}
      >
        <ArrowLeft className="w-6 h-6 fill-current flex-shrink-0" />
      </button>
      <button
        disabled={historyIndex >= history.length - 1}
        className="rounded focus:outline-none hover:text-gray-900 text-gray-700 disabled:text-gray-400 disabled:cursor-default"
        onClick={() => {
          if (historyIndex < history.length - 1) {
            dispatch({ type: "SET_STUFF", payload: { historyIndex: historyIndex + 1 } })
            dispatch(fetchFiles({ ...history[historyIndex + 1], updateHistory: false }))
          }
        }}
      >
        <ArrowRight className="w-6 h-6 fill-current flex-shrink-0" />
      </button>
      <Breadcrumbs />
    </div>
  )
}

function Breadcrumbs() {
  const dispatch = useDispatch()
  const items = [...useSelect("path")].reverse()
  const elements = []
  items.forEach((item, i) => {
    elements.push(
      <button
        className="h-7 hover:text-black hover:font-600 hover:underline focus:outline-none flex-shrink-0"
        key={i}
        onClick={() => i !== items.length - 1 && dispatch(navigateTo(item))}
      >
        {item.name}
      </button>
    )
    elements.push(
      <span className="mx-1" key={`${i}-2`}>
        /
      </span>
    )
  })
  elements.pop()
  return (
    <div className="flex items-center px-2 overflow-x-auto text-14 text-gray-900 bg-gray-400 outline-none flex-1">
      {elements}
    </div>
  )
}

function SearchBar() {
  const dispatch = useDispatch()
  const showDeleted = useSelector((state) => state.showDeleted)
  return (
    <div className="flex items-center my-1">
      <label className="flex items-center mr-1 flex-shrink-0">
        <input
          type="checkbox"
          className="mx-1"
          value={showDeleted}
          onChange={() => dispatch(updateFilters({ showDeleted: !showDeleted }))}
        />
        <span className="text-12">Show Deleted</span>
      </label>
      <input
        className="h-6 text-14 bg-gray-300 flex-1 focus:outline-none px-1"
        onFocus={(e) => {
          e.target.select()
          dispatch(setTyping(true))
        }}
        onBlur={() => dispatch(setTyping(false))}
        onKeyDown={(e) => {
          if (e.code === "Enter") {
            dispatch(setFilterText(e.target.value))
            e.target.blur()
          }
        }}
      />
    </div>
  )
}

const setTyping = (value) => ({ type: "SET_STUFF", payload: { typing: value } })

export const setFilterText = (text) => {
  return (dispatch, getStore) => {
    if (!text) {
      dispatch(updateFilters({ filterText: null, filterFunction: null }))
    } else if (text.toLowerCase().startsWith("where ")) {
      text = text.slice(6, text.length)
      dispatch(updateFilters({ filterText: text, filterFunction: null }))
    } else if (text.startsWith("f.")) {
      const f = new Function("f", "return " + text)
      dispatch(updateFilters({ filterText: null, filterFunction: f }))
    } else {
      const f = new Function("f", `return f.name.toLowerCase().includes("${text.toLowerCase()}")`)
      dispatch(updateFilters({ filterText: null, filterFunction: f }))
    }
  }
}
