import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { FileNameTag } from "./FileTile"
import Modal from "./Modal"
import api from "../api"
import { thumbnailSrc } from "./Hotkeys"

export default function ArtistModalHandler({}) {
  const editingArtist = useSelector((state) => state.editingArtist)
  if (editingArtist) {
    return <ArtistModal artist={editingArtist} key={editingArtist?.id} />
  } else {
    return null
  }
}

function ArtistModal({ artist }) {
  const dispatch = useDispatch()
  const [name, setName] = useState(artist.name)
  const [link, setLink] = useState(artist.link)
  const [todoLevel, setTodoLevel] = useState(artist.todo_level)
  const [checkOnDate, setCheckOnDate] = useState(artist.check_on_date)
  const [showAlbums, setShowAlbums] = useState(false)

  const closeModal = () => dispatch({ type: "SET_STUFF", payload: { editingArtist: null } })
  const saveChanges = () => {
    const attrs = { id: artist.id, name, link, check_on_date: checkOnDate }
    if (todoLevel || todoLevel == 0) attrs.todo_level = parseInt(todoLevel)
    api().POST(`/artists/update_artist`, attrs).then(closeModal)
  }
  return (
    <Modal title="Edit Artist" onCloseClick={closeModal} onClickOutside={closeModal}>
      {showAlbums ? (
        <div className="flex flex-col bg-gray-500 p-6">
          <button
            className="text-14 font-semibold text-gray-300 hover:text-gray-100 hover:underline focus:outline-none mb-4"
            onClick={() => setShowAlbums(false)}
          >
            Hide Album Names
          </button>
          <div className="overflow-y-auto border border-gray-400" style={{ maxHeight: 1200 }}>
            <ul className="px-2 py-1 text-gray-200">
              {artist.album_names.map((albumName, i) => (
                <li key={i}>{albumName}</li>
              ))}
            </ul>
          </div>
        </div>
      ) : (
        <div className="flex flex-col bg-gray-500 p-6">
          <div className="flex justify-between flex-1">
            <div className="flex flex-col">
              <div className="text-14 mb-2 text-gray-200">Artist Name</div>
              <input
                className="w-64 px-2 py-1 bg-gray-300 focus:outline-none"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <div className="text-14 my-2 text-gray-200">
                <span>Link</span>
                {artist.link && (
                  <a className="ml-2" href={artist.link} target="_blank">
                    (Open in new tab)
                  </a>
                )}
              </div>
              <input
                className="w-64 px-2 py-1 bg-gray-300 focus:outline-none"
                value={link || ""}
                onChange={(e) => setLink(e.target.value)}
              />
              <div className="text-14 my-2 text-gray-200">Todo Level</div>
              <input
                className="w-64 px-2 py-1 bg-gray-300 focus:outline-none"
                value={todoLevel || ""}
                onChange={(e) => setTodoLevel(e.target.value)}
              />
              <div className="text-14 my-2 text-gray-200">Check On Date</div>
              <input
                className="w-64 px-2 py-1 bg-gray-300 focus:outline-none"
                value={checkOnDate || ""}
                type="date"
                onChange={(e) => setCheckOnDate(e.target.value)}
              />
            </div>
            <div className="flex flex-col items-center group ml-6">
              {/* <img className="mb-2" src={`http://${window.apiRoot}/images/get_thumbnail/${artist.image_id}.png`} /> */}
              <img className="mb-2" src={thumbnailSrc(artist)} />
              <FileNameTag file={artist} displayName={artist.name} />
              <div className={"text-12 text-gray-200 w-32"}>Total Files: {artist.file_count}</div>
              <div className={"text-12 text-gray-200 w-32"}>Latest File: {artist.latest_file}</div>
              <button
                className="text-14 font-semibold text-gray-300 hover:text-gray-100 hover:underline focus:outline-none mt-8"
                onClick={() => setShowAlbums(true)}
              >
                Show Album Names
              </button>
            </div>
          </div>
          <div className="flex justify-end">
            <button
              className="text-14 font-semibold text-gray-300 hover:text-gray-100 hover:underline focus:outline-none mt-8"
              onClick={saveChanges}
            >
              Save Changes
            </button>
          </div>
        </div>
      )}
    </Modal>
  )
}
