import React from "react"
import ReactDOM from "react-dom"
import classNames from "classnames"

export default function Modal({ title, onCloseClick, onClickOutside, titleClassName, children, zIndex, type }) {
  return ReactDOM.createPortal(
    <div
      {...(onClickOutside && { onClick: onClickOutside })}
      className="fixed h-full w-full inset-0 bg-black bg-opacity-20"
      style={{ zIndex: zIndex || 50 }}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="fixed left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white flex flex-col max-h-screen"
      >
        {title && (
          <div
            className={classNames(
              "flex items-center justify-between text-white h-12 px-6 space-x-2 flex-shrink-0",
              titleClassName,
              type === "error" ? "bg-red-600" : "bg-gray-600"
            )}
          >
            <div className="text-16">{title}</div>
            {onCloseClick && (
              <button
                onClick={onCloseClick}
                className="icon-btn icon-btn-borderless icon-btn-borderless-small text-gray-200 hover:text-white"
              >
                x
              </button>
            )}
          </div>
        )}
        {children}
      </div>
    </div>,
    document.body
  )
}
